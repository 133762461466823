import { ReactNode } from 'react';
import { FlexContainerProps } from '@facephi/inphinite-ui';
import { Header } from '../header';
import { MainStyles } from './Styles';

type Props = FlexContainerProps & {
  children: ReactNode;
  className?: string;
  paddingBig?: boolean;
  noPadding?: boolean;
  noHeader?: boolean;
};

export const Layout = ({
  children,
  className,
  paddingBig = false,
  noPadding = false,
  noHeader = false,
  ...flexProps
}: Props) => {
  return (
    <>
      {!noHeader && <Header />}
      <MainStyles
        as="main"
        className={className}
        flex="1"
        paddingBig={paddingBig}
        noPadding={noPadding}
        {...flexProps}
      >
        {children}
      </MainStyles>
    </>
  );
};
