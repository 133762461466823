import React, { useEffect } from 'react';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { I18nextProvider, initReactI18next } from 'react-i18next';

type IProps = {
  children: React.ReactNode;
  language?: string;
};

export enum Languages {
  es = 'es',
  en = 'en',
  pt = 'pt',
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: (lng) => `/locales/${lng[0].split('-')[0]}.json`,
    },
    fallbackLng: Languages.en,
    interpolation: {
      escapeValue: false,
    },
  });

export const TranslationProvider = ({ children, language }: IProps) => {
  useEffect(() => {
    i18n.changeLanguage(language || Languages.en);
  }, []);

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};
