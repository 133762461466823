function extractSubdomain(domain: string) {
  const rx = /(.*).landing.facephi.(.*)/g;
  const arr = rx.exec(domain);

  if (arr) {
    return arr[1];
  } else {
    return null;
  }
}

export function useClient() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: any = new Proxy(new URLSearchParams(window.location.search), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get: (searchParams, prop) => searchParams.get(prop as any),
  });

  const subdomain = extractSubdomain(window.location.hostname);

  return {
    client: subdomain ? subdomain : params.client,
  };
}
