import { lazy, Suspense } from 'react';
import { Spinner } from '@facephi/inphinite-ui';
import { compare } from 'compare-versions';
import { browserName, fullBrowserVersion } from 'react-device-detect';
import {
  BrowserRouter as Router,
  Route,
  Routes as Switch,
} from 'react-router-dom';
import { BrowserList, RoutesName } from '../state/constants';

const Home = lazy(() => import('../pages/Home'));
const Page404 = lazy(() => import('../pages/404'));
const Finish = lazy(() => import('../pages/Finish'));
const BrowserError = lazy(() => import('../pages/BrowserError'));

export const Routes = () => {
  const validateBrowser = () => {
    const currentBrowser = BrowserList.find(
      (item) => item.name === browserName
    );
    return (
      currentBrowser &&
      compare(fullBrowserVersion, currentBrowser.version, '>=')
    );
  };

  return (
    <Router>
      <Suspense fallback={<Spinner />}>
        {validateBrowser() ? (
          <Switch>
            <Route path={RoutesName.main} element={<Home />} />
            <Route path={RoutesName.home} element={<Home />} />
            <Route path={RoutesName.finish} element={<Finish />} />
            <Route path="*" element={<Page404 />} />
          </Switch>
        ) : (
          <BrowserError />
        )}
      </Suspense>
    </Router>
  );
};
