import { Suspense } from 'react';
import { Spinner } from '@facephi/inphinite-ui';
import { InphiniteThemeProvider, InphiniteTheme } from '@facephi/inphinite-ui';

import Error404 from '../pages/404';
import { TranslationProvider, useBuilder } from '../providers';
import { Routes } from '../routes/Routes';
import { FacephiTheme } from '../styles';
import { GlobalStyles } from '../styles';

type IProps = {
  error?: boolean;
};

export const Main = ({ error = false }: IProps) => {
  const { language, theme, title } = useBuilder();

  const landingTheme = Object.assign(FacephiTheme, {
    ...InphiniteTheme,
    color: {
      ...InphiniteTheme.color,
      primary: {
        ...InphiniteTheme.color.primary,
        [100]: theme?.primaryColor,
        [400]: theme?.secondaryColor,
        [500]: theme?.terciaryColor,
      },
    },
    images: {
      ...FacephiTheme.images,
      logoAside: theme?.logo,
    },
    title: title,
  });

  return (
    <InphiniteThemeProvider theme={landingTheme}>
      <TranslationProvider language={language}>
        <GlobalStyles />
        <Suspense fallback={<Spinner />}>
          {error ? <Error404 /> : <Routes />}
        </Suspense>
      </TranslationProvider>
    </InphiniteThemeProvider>
  );
};
