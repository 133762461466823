import Chrome from '../assets/img/chrome.png';
import Edge from '../assets/img/edge.png';
import Firefox from '../assets/img/firefox.png';
import Safari from '../assets/img/safari.png';
import { BrowserListProps } from './model';

export enum RoutesName {
  home = '/operation/:customerId',
  main = '/',
  finish = '/finish',
}

export const termsLocalStorage = 'terms';

export enum DeviceSize {
  mobile = 768,
  desktop = 1024,
}

export const BrowserList: BrowserListProps[] = [
  {
    id: 1,
    image: Chrome,
    name: 'Chrome',
    version: '101.0.0',
    route: 'https://www.google.com/chrome/',
    installable: true,
  },
  {
    id: 2,
    image: Chrome,
    name: 'Chrome Headless',
    version: '100.0.0',
    installable: false,
  },
  {
    id: 3,
    image: Firefox,
    name: 'Firefox',
    version: '90.0.0',
    route: 'https://www.mozilla.org/es-ES/firefox/new/',
    installable: true,
  },
  {
    id: 4,
    image: Edge,
    name: 'Edge',
    version: '90.0.0',
    route: 'https://www.microsoft.com/es-es/edge',
    installable: true,
  },
  {
    id: 5,
    image: Safari,
    name: 'Safari',
    version: '12.1.2',
    route: 'https://support.apple.com/es_ES/downloads/safari',
    installable: true,
  },
  {
    id: 5,
    image: Safari,
    name: 'Mobile Safari',
    version: '12.1.2',
    installable: false,
  },
];

export const defaultTheme = {
  logo: 'https://dev-fphi-landing-builder.s3.eu-west-1.amazonaws.com/logo-facephi.svg',
  primaryColor: '#697bdf',
  secondaryColor: '#2B47E6',
  terciaryColor: '##1f3de5',
};
