import { FlexContainer } from '@facephi/inphinite-ui';
import styled from 'styled-components/macro';
import { desktopStyles } from '../../styles';

export const MainStyles = styled(FlexContainer)<{
  paddingBig?: boolean;
  noPadding?: boolean;
}>`
  overflow: auto;

  padding: ${(props) =>
    props.noPadding ? 0 : props.paddingBig ? '2.8rem' : '1rem'};
  position: relative;

  ${desktopStyles} {
    padding: ${(props) =>
      props.noPadding ? 0 : props.paddingBig ? '5.6rem' : '3.2rem'};
  }
`;
