import {
  ReactNode,
  useEffect,
  useState,
  createContext,
  useContext,
  cloneElement,
  ReactElement,
} from 'react';
import { WorkflowDTO } from '@facephi/sdk-web';

import { useRequest, RequestMethods, useClient } from '../hooks';
import { defaultTheme } from '../state/constants';
import { ThemeDto } from '../state/model';

type IProps = {
  children: ReactNode;
};

export type BuilderContextProps = {
  language?: string;
  tenantId?: string;
  theme?: ThemeDto;
  workflow?: WorkflowDTO;
  title?: string;
};

export const BuilderContext = createContext<BuilderContextProps>({
  language: 'es',
});

export const BuilderProvider = ({ children }: IProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const [theme, setTheme] = useState<ThemeDto>();
  const [workflow, setWorkflow] = useState<WorkflowDTO>();
  const [language, setLanguage] = useState<string>();
  const [tenantId, setTenantId] = useState<string>();
  const [title, setTitle] = useState<string>();

  const { client } = useClient();

  const apiUrlBuilder = process.env.REACT_APP_API_BUILDER;
  const { request } = useRequest();

  const getDefaultData = () => {
    setTheme(defaultTheme);
    setError(true);
    setLoading(false);
  };

  useEffect(() => {
    if (client) {
      loadInitialData();
    } else {
      getDefaultData();
    }
  }, []);

  const loadInitialData = () => {
    return request(`${apiUrlBuilder}/landing/${client}`, {
      method: RequestMethods.get,
    })
      .catch(() => {
        getDefaultData();
        return false;
      })
      .then((response) => {
        if (response) {
          setLanguage(response.configuration.language);
          setTenantId(response.configuration.tenantId);
          setTheme(response.theme);

          setWorkflow(response.workflow.definition);
          setTitle(response.configuration.title);

          document.title = response.configuration.title;
        }

        setLoading(false);
        return true;
      });
  };

  return (
    <BuilderContext.Provider
      value={{ language, tenantId, theme, workflow, title }}
    >
      {loading
        ? null
        : error
        ? cloneElement(children as ReactElement, { error: true })
        : children}
    </BuilderContext.Provider>
  );
};

export const useBuilder = () => useContext(BuilderContext);
