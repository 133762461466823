import { Icon, Label } from '@facephi/inphinite-ui';
import styled from 'styled-components/macro';

export const FinishMessage = styled(Label)`
  margin: 2rem 0 3rem;
`;

export const FinishIcon = styled(Icon)`
  margin-bottom: 1rem;
`;
