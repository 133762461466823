import { useTheme } from '@facephi/inphinite-ui';
import { HeaderStyles } from './Styles';

export const Header = () => {
  const theme = useTheme();
  return (
    <HeaderStyles as="header" alignItems="center" justifyContent="center">
      <img src={theme.images?.logoAside} alt="logo" />
    </HeaderStyles>
  );
};
