/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ReactDOM from 'react-dom';
import { Main } from './components';
import { BuilderProvider } from './providers';

ReactDOM.render(
  <React.StrictMode>
    <BuilderProvider>
      <Main />
    </BuilderProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
