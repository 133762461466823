import { FlexContainer } from '@facephi/inphinite-ui';
import styled from 'styled-components/macro';
import { desktopStyles } from '../../styles';

export const HeaderStyles = styled(FlexContainer)`
  min-height: 7rem;
  height: 7rem;
  width: 100%;
  padding: 0 1.6rem;
  background-color: ${(props) => props.theme.color.primary[400]};

  img {
    height: 100%;
    max-height: 100%;
    padding: 1rem 0;
    object-fit: contain;
  }

  ${desktopStyles} {
    padding: 0 18rem;
  }
`;
