import { Label } from '@facephi/inphinite-ui';
import { useTranslation } from 'react-i18next';
import { Layout } from '../components';
import { FinishIcon, FinishMessage } from '../components/finishView';

const Page404 = () => {
  const { t } = useTranslation();

  return (
    <Layout
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      data-test="404"
    >
      <FinishIcon iconName="XCircle" size="120" color="error" />
      <Label size="21" semibold>
        Ooops!
      </Label>
      <FinishMessage size="18" textAlign="center">
        {t('The page you are looking for is not available')}
      </FinishMessage>
    </Layout>
  );
};

export default Page404;
